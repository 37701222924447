.swatch-opt,
.filter-options-content {
  .swatch-attribute {
    margin-bottom:4rem;

    .swatch-attribute {
      &-label {
        display: inline-block;
        width: auto;
        margin-bottom: 1rem;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 500;
        line-height: 3rem;
        letter-spacing: 4px;
        text-transform: uppercase;
      }

      &-selected-option {
        display: inline-block;
        margin-left: 2rem;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 500;
        line-height: 3rem;
        letter-spacing: 4px;
        text-transform: uppercase;
      }

      &-options {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        gap: 1rem;

        .swatch-option {
          background: $colorWhite;
          cursor: pointer;
          box-shadow: 1px 1px 5px 1px rgba(0,0,0,0.15);

          &.image {
            min-height: 9.8rem;
            min-width: 7rem;
          }

          &.color {
            max-width:4.8rem;
            max-height:4.8rem;
            min-width:4.8rem;
            min-height:4.8rem;
          }

          &.text {
            display: flex;
            align-items: center;
            padding: 0 2rem;
            font-size: 1.4rem;
            font-style: normal;
            font-weight: 500;
            line-height: 3rem;
            letter-spacing: 4px;
            user-select: none;
            text-transform: uppercase;
            min-width:4.8rem;
            min-height:4.8rem;
          }

          &.selected {

          }

          &.disabled {
            opacity: 0.4;
            cursor:not-allowed;
          }
        }
      }
    }

    .swatch-input {
      display:none;
    }
  }
}

.swatch-option-tooltip {
  display:none !important;
}

.filter-options-content {
  .swatch-attribute {
    .swatch-attribute {
      &-options {
        justify-content: center;
      }
    }
  }
}

@media only screen and (max-width: $phone) {
  .swatch-opt,
  .filter-options-content {
    .swatch-attribute {
      .swatch-attribute {
        &-label {
          font-size: 1.1rem;
          line-height: 2.4rem;
        }

        &-selected-option {
          font-size: 1.1rem;
          line-height: 2.4rem;
        }

        &-options {
          .swatch-option {
            &.text {
              font-size: 1.1rem;
              line-height: 2.4rem;
            }
          }
        }
      }
    }
  }
}