body {
  #CybotCookiebotDialog {
    font-family: sans-serif
  }

  #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyButtonAccept,
  #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonAccept,
  #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
    background-color: #000;
    border-color: #000;
    border: 1px solid #000 !important;
    border-radius: 0
  }

  #CybotCookiebotDialog.CybotEdge #CybotCookiebotDialogBodyEdgeMoreDetails a {
    color: #000
  }

  #CybotCookiebotDialogBodyLevelButtonsSelectPane label:not([for=CybotCookiebotDialogBodyLevelButtonNecessary]) .CybotCookiebotDialogBodyLevelButtonDescription:hover {
    color: #000
  }

  #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonCustomize,
  #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection,
  #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyButtonDecline {
    border-color: #000;
    border: 1px solid #000 !important
  }

  #CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton {
    border-color: #000;
    border: 1px solid #000 !important;
    border-radius: 0
  }

  #CybotCookiebotDialogTabContent input+.CybotCookiebotDialogBodyLevelButtonSlider {
    background-color: rgba(0,0,0,.7)
  }

  #CybotCookiebotDialogTabContent input:checked+.CybotCookiebotDialogBodyLevelButtonSlider {
    background-color: #000
  }

  #CybotCookiebotDialogTabContent input[type=checkbox][disabled]:checked+.CybotCookiebotDialogBodyLevelButtonSlider {
    background-color: #304b64
  }

  #CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink.CybotCookiebotDialogActive {
    border-color: #000;
    color: #000
  }

  #CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink:hover {
    color: #000
  }

  .CybotCookiebotDialogTabPanel button {
    width: auto
  }

  #CookiebotWidget #CookiebotWidget-buttons #CookiebotWidget-btn-change {
    background-color: #000;
    border-color: #000;
    border: 1px solid !important;
    border-radius: 0
  }

  #CookiebotWidget #CookiebotWidget-buttons #CookiebotWidget-btn-withdraw {
    border-color: #000;
    border: 1px solid !important;
    border-radius: 0
  }

  #CookiebotWidget .CookiebotWidget-consent-details button {
    color: #000
  }

  #CookiebotWidget .CookiebotWidget-body .CookiebotWidget-consents-list li.CookiebotWidget-approved svg {
    fill: #000
  }

  #CybotCookiebotDialog #CybotCookiebotDialogBodyContentText a,
  #CybotCookiebotDialog #CybotCookiebotDialogBodyLevelButtonIABHeaderViewPartnersLink,
  #CybotCookiebotDialog #CybotCookiebotDialogDetailBulkConsentList dt a,
  #CybotCookiebotDialog #CybotCookiebotDialogDetailFooter a,
  #CybotCookiebotDialog .CybotCookiebotDialogBodyLevelButtonIABDescription a,
  #CybotCookiebotDialog .CybotCookiebotDialogDetailBodyContentCookieLink,
  #CybotCookiebotDialogDetailBodyContentTextAbout a {
    color: #000
  }

  #CybotCookiebotDialog .CookieCard .CybotCookiebotDialogDetailBodyContentCookieContainerButton:hover,
  #CybotCookiebotDialog .CookieCard .CybotCookiebotDialogDetailBodyContentIABv2Tab:hover,
  #CybotCookiebotDialogDetailBodyContentCookieContainerTypes .CybotCookiebotDialogDetailBodyContentCookieProvider:not(.CybotCookiebotDialogDetailBodyContentCookieInfoCount):hover {
    color: #000
  }

  #CybotCookiebotDialogTabContent .CybotCookiebotDialogDetailBulkConsentCount {
    background-color: rgba(0,0,0,.7)
  }

  #CookiebotWidget .CookiebotWidget-logo svg circle {
    fill: #000
  }

  #CybotCookiebotDialogPoweredbyCybot {
    display: none
  }
}