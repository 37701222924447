body {
  .loading-mask {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    z-index: 99;
    background: rgba($colorLightGrey, 0.5);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .loader {
      > img {
        max-width: 4.4rem;
        position: static !important;
        display: block;
      }
      > p {
        display:none;
      }
    }
  }

  > .loading-mask {
    height: calc(var(--vh, 1vh) * 100) !important;
    position: fixed;
  }
}
