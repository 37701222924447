body {
  &._has-modal {
    overflow:hidden;
    position: relative;

    &.cms-home {
      .page-wrapper .page-main .columns {
        filter: blur(0) opacity(1);
      }

      .page-wrapper .page-main .columns .column.main .content-type:not(.webcrossing-nav) {
        filter: blur(4px) opacity(0.7);
      }
    }

    .page-wrapper .page-main .columns {
      filter: blur(4px) opacity(0.7);
    }
  }

  .modals-wrapper {
    .modals-overlay {
      position: fixed;
      content: "";
      height: 100vh;
      left: 0;
      top: 0;
      width: 100vw;
      background: rgba($colorWhite, 0.3);
      display:none;
    }

    .modal-slide,
    .modal-popup {
      min-width: 0;
      position: fixed;
      z-index: 900;
      right: 0;
      top: 0;
      bottom: 0;
      left: 0;
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s .3s,opacity .3s ease;

      &._show {
        visibility: visible;
        opacity: 1;
        transition: opacity .3s ease;
      }

      &.modal-newsletter {
        left: auto;
        position: fixed;
        right: clamp(40px,10vw,1000px);
        top: auto;
        bottom: 0;

        @media (min-width: 1921px) {
          right: calc((100vw - 1520px) / 2);
        }

        @media (max-width: $tablet) {
          right: auto;
          left:50%;
          transform:translate(-50%, 0);
          width: calc(100% - 60px);
        }

        .modal-inner-wrap {
          max-width: 480px;
          background: $colorBlack;
          color:$colorWhite;
          padding: 0;
          width: 100%;
          overflow: inherit;
          min-height: 550px;
          height: 100%;

          @media (max-width: 600px) {
            max-height: none;
          }

          .modal-header {
            min-height: auto;
            margin-bottom:0;

            .action-close{
              background: $colorSecondary;
              color:$colorWhite;
              border-radius:50%;
              right: -25px;
              top: -25px;
              max-height: 50px;

              &:before {
                line-height: 50px;
                width: 50px;
                height: 50px;
                color:$colorWhite;
                font-size: 28px;
                font-weight: 300;
              }
            }
          }

          .modal-content {
            padding: 45px 45px 25px 45px;

            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
              color:$colorWhite;
              hyphens: inherit;
              line-height:5.1rem;
              margin-bottom: 60px;
            }

            .form {
              #newsletter {
                background: $colorBlack;
                color:$colorWhite;
                border-bottom:1px solid $colorWhite;
                font-family: 'PPNeueMontreal', sans-serif;
                font-size:2rem;
                line-height:3rem;
                letter-spacing: 1.5px;
                text-transform: none;
                font-weight: 300;
                padding: 20px 0;

                &:-moz-placeholder {
                  color:$colorWhite;
                  font-family: 'PPNeueMontreal', sans-serif;
                  font-size:2rem;
                  line-height:3rem;
                  letter-spacing: 1.5px;
                  text-transform: none;
                  font-weight: 300;
                }
                &::-moz-placeholder {
                  color:$colorWhite;
                  font-family: 'PPNeueMontreal', sans-serif;
                  font-size:2rem;
                  line-height:3rem;
                  letter-spacing: 1.5px;
                  text-transform: none;
                  font-weight: 300;
                }
                &:-ms-input-placeholder {
                  color:$colorWhite;
                  font-family: 'PPNeueMontreal', sans-serif;
                  font-size:2rem;
                  line-height:3rem;
                  letter-spacing: 1.5px;
                  text-transform: none;
                  font-weight: 300;
                }
                &::-webkit-input-placeholder {
                  color:$colorWhite;
                  font-family: 'PPNeueMontreal', sans-serif;
                  font-size:2rem;
                  line-height:3rem;
                  letter-spacing: 1.5px;
                  text-transform: none;
                  font-weight: 300;
                }
              }

              .actions {
                display:flex;
                justify-content: space-between;
                gap:40px;
                margin-top: 2rem;

                @media (max-width: 600px) {
                  flex-direction: column;
                  gap: 20px;
                }

                .privacy {
                  display: flex;
                  justify-content: space-between;
                  gap: 12px;
                  align-items: baseline;
                  flex-wrap: wrap;

                  #privacy-error {
                    width:100%;
                  }

                  input {
                    appearance: none;
                    background-color: #000;
                    margin: 0;
                    width: 14px;
                    height: 14px;
                    border: 1px solid #fff;
                    display: grid;
                    place-content: center;
                    padding: 0;

                    &:before {
                      content: "";
                      width: 8px;
                      height: 8px;
                      transform: scale(0);
                      transition: 120ms transform ease-in-out;
                      background: #fff;
                    }

                    &:checked {
                      &:before {
                        transform: scale(1) !important;
                      }
                    }
                  }

                  label {
                    font-family: 'PPNeueMontreal', sans-serif;
                    font-size:1.3rem;
                    line-height:1.8rem;
                    text-transform: none;
                    letter-spacing: 0;
                    width: calc(100% - 30px);
                  }
                }

                button {
                  background-color:$colorSecondary;
                  padding: .7rem 3rem;
                }
              }

              .hint {
                margin-top:5rem;
                font-family: 'PPNeueMontreal', sans-serif;
                font-size:1.3rem;
                line-height:1.8rem;
                text-transform: none;
              }
            }
          }
        }
      }

      .modal-inner-wrap {
        background: $colorWhite;
        @include content(outer);
        padding: 2.5rem;
        width: 70%;
        max-height: 90%;
        overflow-x:hidden;
        overflow-y: scroll;

        .modal-header {
          position: relative;
          min-height: 5rem;
          margin-bottom: 2rem;

          .action-close{
            @include overlay(0, right, null, null);
            @include fontDefault(0);
            font-size: 0;
            border: 0;
            background: transparent;
            cursor: pointer;

            &:before{
              content: $iconClose;
              display: inline-block;
              text-align: center;
              @include fontIcon(2, normal, $colorPrimary);
              height: 2.3rem;
              width: 2.3rem;
              @include flex(centery, $flex: false);
              margin: 0 auto;
            }
          }
        }

        .modal-content {
          #shipping-new-address-form {
            > .field {
              margin-bottom: 1.5rem;
              padding:0;

              .field {
                margin-bottom:0;
              }

              &.street {
                legend {
                  display: none;
                }
              }
            }
          }
        }

        .modal-footer {
          display:flex;
          flex-wrap: wrap;
          gap:2rem;
          margin-top:2rem;
        }
      }
    }
  }
}

@media only screen and (max-width: $tablet) {
  body {
    .modals-wrapper {
      .modal-slide,
      .modal-popup {
        .modal-inner-wrap {
          width: 90%;
          max-height: 100%;

          .modal-footer {
            button {
              width:100%;
            }
          }
        }
      }
    }
  }
}