form {
  fieldset {
    border: none;
    display: block;
    padding: 1rem 0 2rem 0;

    hr {
      display:none;
    }

    legend {
      font-weight:500;
      margin-bottom: 2rem;
    }
  }

  .field {
    margin-bottom: 1rem;

    &.required > label,
    &._required > label {
      position: relative;

      &:after {
        font-family: $fontIcon;
        font-weight: 400;
        content: "";
        color: $colorPrimary;
        position: absolute;
        font-size: .8rem;
        line-height: 1;
      }
    }

    &.note {
      margin-bottom:2rem;
    }
  }

  .control {
    input,
    select {
      margin-bottom: 1rem;
    }

    &._with-tooltip {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      position: relative;

      .input-text {
        flex-basis: calc(100% - 4rem);
      }

      .field-tooltip {
        cursor: pointer;
        position: absolute;
        right: 0.8rem;
        top: 1.1rem;

        &.toggle {
          &._active {
            .field-tooltip-content {
              display:block;
            }
          }

          .label {
            border: 0;
            clip: rect(0, 0, 0, 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
          }

          .field-tooltip-action {
            &:before {
              font-family: $fontIcon;
              font-weight: 300;
              font-size: 2rem;
              color: #333;
              content: "\f059";
            }
          }

          .field-tooltip-content {
            background: $colorGrey;
            font-size: $fontSizeInput;
            line-height: 3rem;
            padding: .8rem 2rem;
            min-width: 27rem;
            display: none;
            position: absolute;
            text-transform: none;
            right: 3rem;
            top: 50%;
            transform: translate(0, -50%);
            word-wrap: break-word;
            z-index: 2;
            box-shadow: 1px 1px 5px 1px rgba(0,0,0,.15);
          }
        }
      }
    }
  }

  div.mage-error,
  div.field-error {
    display: block;
    width:100%;
    font-size: $fontSizeSmall;
    line-height: $lineHeightSmall;
    font-weight: 400;
    color:$colorError;
  }

  div.message {
    display: block;
    width:100%;
    //font-size: $fontSizeSmall;
    //line-height: $lineHeightSmall;
    font-weight: 400;

    &.error {
      color: $colorError;
    }

    &.success {
      color: $colorSuccess;
    }

    &.info,
    &.empty {
      color: $colorInfo;
    }

    &.warning,
    &.notice {
      color: $colorWarning;
    }
  }

  #password-strength-meter-container {
    font-size: $fontSizeSmall;
    line-height: $lineHeightSmall;
    color:$colorPrimary;
    font-weight: 400;

    &.password-weak {
      color:$colorError;
    }

    &.password-medium {
      color:$colorWarning;
    }

    &.password-strong,
    &.password-very-strong {
      color:$colorSuccess;
    }
  }
}


input, select, textarea {
  width: 100%;
  padding: 1.4rem 3rem;
  padding: 0.8rem 2rem;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.15);
  background: $colorWhite;
  border:0;

  &[readonly=""] {
    background: $colorGrey;
    color: rgba($colorPrimary, .5);
    cursor: not-allowed;
  }

  &[disabled=""] {
    opacity:.5;
  }

  &[type="text"],
  &[type="email"],
  &[type="number"] {
    -webkit-appearance: none;
    border-radius:0;
  }

  &[type="radio"],
  &[type="checkbox"] {
    width:auto;
  }
}

select {
  height: 4.6rem;
  box-sizing: border-box;
  border-right: 1.6rem solid transparent;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M267.3 395.3c-6.2 6.2-16.4 6.2-22.6 0l-192-192c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0L256 361.4 436.7 180.7c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6l-192 192z"/></svg>') no-repeat right center;
}

address,
.address,
.shipping-address-item,
.billing-address-details,
.shipping-information-content {
  font-size:$fontSizeInput;
  line-height: 2.2rem;
}