@mixin content($type: null){
  @if ($type == max) {
    max-width: $maxWidth;
  }
  @if ($type == large) {
    max-width: $largeWidth;
  }
  @if ($type == medium) {
    max-width: $mediumWidth;
  }
  @if ($type == small) {
    max-width: $smallWidth;
  }
  @if ($type == tiny) {
    max-width: $tinyWidth;
  }
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

@mixin spaceX($space: null, $spaceRight: null){
  @if $spaceRight {
    padding-left: $space;
    padding-right: $spaceRight;
  } @else if $space {
    padding-left: $space;
    padding-right: $space;
  }
}

@mixin spaceY($space: null, $spaceBottom: null){
  @if $spaceBottom {
    padding-top: $space;
    padding-bottom: $spaceBottom;
  } @else if $space {
    padding-top: $space;
    padding-bottom: $space;
  }
}

@mixin flexDefault {
  display: flex;
}

@mixin flex(
  $justify: null,
  $align: null,
  $direction: null,
  $wrap: null,
  $flex: true,
  $gap: false
) {
    @if $flex {
      @include flexDefault;
    }@else{
    }
    @if $gap {
      gap: $gap;
    }@else{
    }
    @if $justify == centery{
      align-items: center;
      justify-content: center;
    } @if $justify == flex-endy{
      align-items: flex-end;
      justify-content: flex-end;
    } @if $justify == flex-starty{
      align-items: flex-start;
      justify-content: flex-start;
    } @if $justify == space-betweeny{
      align-items: center;
      justify-content: space-between;
    } @else{
      justify-content: $justify;
      align-items: $align;
    }
    flex-direction: $direction;
    @if $wrap == true {
      flex-wrap: wrap;
    }@else if $wrap == false{
      flex-wrap: nowrap;
    }
}

@mixin fontIcon(
  $sizeValue: 2,
  $iconWeight:  300,
  $color: null,
  $lineHeight: $sizeValue + rem
) {
    font-family: $fontIcon;
    font-weight: $iconWeight;
    font-size: $sizeValue + rem;
    color: $color;
    line-height: $lineHeight;
}

// ** fontDefault **  font-size($sizeValue), line-height($lineHeight), color($fontColor), font-weight($fontWeight)
@mixin fontDefault(
  $sizeValue: $fontSizeDefault,
  $lineHeight: $lineHeightDefault,
  $color: $colorFontDefault,
  $weight: null,
  $transform: null,
  $style: null,
  $fontStyle:null,
  $letterSpacing: null,
  $font: false
) {
  @if ($sizeValue != null) {
    font-size: $sizeValue + rem;
  }
  /* font-size */
  @if ($lineHeight != null) {
    line-height: $lineHeight ;
  }
  color: $color;
  font-weight: $weight;
  text-transform: $transform;
  text-decoration: $style;
  font-style: $fontStyle;
  letter-spacing: $letterSpacing;
  @if $font == true{
    font-family: $font;
  }@else if $font == $fontSpecial{
    font-family: $fontSpecial;
  }
}

@mixin overlay(
  $directionV: top,
  $directionH: left,
  $width: 100%,
  $height: 100%,
  $position: absolute,
  $right: false,
  $bottom: false,
  $top: false,
  $left: false,
  $z-index: null,
  $content: ''
){
    position: $position;
    height: $height;
    width: $width;
    z-index: $z-index;
    content: $content;
    @if $directionV == top {
      top: 0;
    } @else if $directionV == bottom {
      bottom: 0;
    } @else if $top {
      top: $directionV;
    } @else if $bottom {
      bottom: $directionV;
    } @else {
      top: $directionV;
    }
    @if $directionH == left {
      left: 0;
    } @else if $directionH == right {
      right: 0;
    } @else if $left {
      left: $directionH;
    } @else if $right {
      right: $directionH;
    } @else {
      left: $directionH;
    }
}

/* default :1px solid $colorBorder */
@mixin border($size: 1px, $color: $colorBorder, $weight: solid, $position: null, $radius: false, $radiusPX: null) {
  @if $position == top {
    border-top: $size $weight $color;
  } @else if $position == bottom {
    border-bottom: $size $weight $color;
  } @else if $position == left {
    border-left: $size $weight $color;
  } @else if $position == right {
    border-right: $size $weight $color;
  } @else {
    border: $size $weight $color;
  }
  @if $radius == true {
    border-radius: $radiusPX;
  }
}

@mixin borderButton($size: 1px, $color: $colorBorder, $weight: solid, $position: null, $border:true, $radius: false, $radiusPX: null){
  @if $border{
    @if $position == top {
      border-top: $size $weight $color;
    } @else if $position == bottom {
      border-bottom: $size $weight $color;
    } @else if $position == left {
      border-left: $size $weight $color;
    } @else if $position == right {
      border-right: $size $weight $color;
    } @else {
      border: $size $weight $color;
    }
  }
  @if $radius == true {
    border-radius: $radiusPX;
  }
}
@mixin boxShadow($hLength: null, $vLength:null, $blur: null, $spread: null, $color: null){
  -webkit-box-shadow: $hLength + rem $vLength + rem $blur + rem $spread + rem $color;
  -moz-box-shadow: $hLength + rem $vLength + rem $blur + rem $spread + rem $color;
  box-shadow: $hLength + rem $vLength + rem $blur + rem $spread + rem $color;
}
@mixin transition($all: all, $effect: ease, $duration: .2s) {
  -webkit-transition: $all $effect $duration;
  -o-transition: $all $effect $duration;
  transition: $all $effect $duration;

}

