.block-search{
  @include flex();

  .block{
    &-title {
      padding: 0;
      margin: 0;
      border: 0;
      cursor: pointer;

      &:before {
        @include fontIcon($sizeValue: 1.6, $lineHeight: 3rem);
        margin-right: 1rem;
        content: $iconSearch;
        display: block;
      }
      strong {
        display: none;
      }
    }
    &-content {
      form{
        @include flex(centery);

        .field {
          margin:0;

          label {
            display: none;
          }

          input {
            border:0;
            background:transparent;
            padding:0;
            max-width: 15rem;
            box-shadow: none;
            margin:0;
          }
        }
      }

      .action{
        &.search {
          padding: 0;
          border: 0;
          margin-right: 0;
          display: none;
          background: transparent;
          &:before {
            @include fontIcon;
            margin-right: 2rem;
            content: $iconSearch;
            display: block;
          }
          span {
            display: none;
          }
        }
      }
    }
  }
}