.toolbar {
  .pages {
    > .items {
      display:flex;
      flex-wrap:wrap;
      justify-content: center;
      gap: 2.5rem;
      margin-bottom: 2rem;

      > .item {
        strong {
          span {
            color: rgba(0, 0, 0, 1);
          }
        }

        span {
          font-size: 4.5rem;
          font-weight: 400;
          line-height: 6rem;
          letter-spacing: 1px;
          color: rgba(0, 0, 0, 0.20);

          &.arrow-left {
            background: url($iconDir + "arrow-left.svg") no-repeat center;
            background-size: 100%;
            width: 2.6rem;
            height: 100%;
            display: block;
          }

          &.arrow-right {
            background: url($iconDir + "arrow-right.svg") no-repeat center;
            background-size: 100%;
            width: 2.6rem;
            height: 100%;
            display: block;
          }
        }
      }
    }
  }

  .toolbar-amount {
    width: 100%;
    text-align: center;

    .toolbar-number {
      color:$colorSecondary;
    }
  }
}

@media only screen and (max-width: $tablet) {
  .toolbar {
    .pages {
      > .items {
        gap: 1rem;
        margin-bottom: 0;

        > .item {
          span {
            font-size: 2.4rem;
            line-height: 4rem;

            &.arrow-right {
              width: 1.6rem;
            }

            &.arrow-left {
              width: 1.6rem;
            }
          }
        }
      }
    }

    .toolbar-amount {
      font-size:1.1rem;
      line-height:3rem;
    }
  }
}
