button {
  &.action {
    border: 0;
    outline: 0;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 3rem;
    letter-spacing: 4px;
    text-transform: uppercase;
    padding: 0.7rem 2rem;
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.15);
    display:inline-block;
    background-color:$colorPrimary;
    color:$colorWhite;
    cursor:pointer;

    &.action-show {
      padding: 0;
      background-color: transparent;
      box-shadow: none;
      color: $colorPrimary;
      text-decoration: underline;
      text-align: left;
    }

    &.checkout,
    &.continue,
    &.action-selected-shipping-item {
      background-color:$colorSecondary;
    }

    &.disabled {
      opacity:0.5;
      cursor:not-allowed;
      pointer-events: none;
    }
  }
}

a {
  &.action {
    &.create,
    &.continue,
    &.success-create-account,
    &.btn-remove {
      border: 0;
      outline: 0;
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 3rem;
      letter-spacing: 4px;
      text-transform: uppercase;
      padding: 0.8rem 2rem;
      box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.15);
      display:inline-block;
      background-color:$colorPrimary;
      color:$colorWhite;
      cursor:pointer;
      text-align: center;
    }

    &.btn-remove {
      margin-top:1rem;
    }

    &.back,
    &.remind {
      border: 0;
      outline: 0;
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 3rem;
      letter-spacing: 4px;
      text-transform: uppercase;
      padding: 0.8rem 2rem;
      box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.15);
      display:inline-block;
      background-color:$colorWhite;
      color:$colorPrimary;
      cursor:pointer;
    }
  }
}

@media only screen and (max-width: $phone) {
  button {
    &.action {
      font-size: 1.1rem;
    }
  }

  a {
    &.action {
      &.create,
      &.continue,
      &.success-create-account {
        font-size: 1.1rem;
      }

      &.back,
      &.remind {
        font-size: 1.1rem;
      }
    }
  }
}