body {
  &.mmenu-active {
    header {
      .header-content {
        .wrapper {
          .main {
            .section {
              .item {
                &.mmenu-header-open {
                  button {
                    &:before {
                      content: "\f00d";
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  header {
    .header-content {
      .wrapper {
        .main {
          .section {
            .item {
              &.mmenu-header-open {
                button {
                  &:before {
                    font-size: 1.4rem;
                    line-height: 3rem;
                    font-weight: 300;
                    font-family: "Font Awesome 6 Pro";
                    content: "\f0c9";
                    color: $colorBlack;
                    margin-right: 1rem;
                    min-width: 1.7rem;
                    display: inline-block;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.mmenu-wrapper {
  display: none;
  position: absolute;
  height: calc(100vh - 15rem);
  max-height: 64rem;
  width: calc(100% - 2.5rem);
  max-width:35rem;
  z-index: 10;
  top: 10.4rem;
  transition: all ease 0.2s;

  &.active {
    display:block;
  }

  .mmenu-content {
    background: $colorPrimary;
    height: 100%;

    .mmenu-main {
      padding:0;
      height: calc(100% - 5.5rem);

      .navigation {
        padding: 2.5rem 0;
        height: 100%;

        ul {
          height: 100%;
          padding: 0 3rem;
          overflow-y: auto;
          overflow-x: hidden;

          li {
            display: flex;
            flex-direction: column;
            width: 100%;

            &.level0 {
              & > a {
                padding: 3rem 0 0 0;
                display: flex;
                justify-content: space-between;
                font-weight: 400;
                font-size: 3.4rem;
                line-height: 4.4rem;

                &.active {
                  color: $colorWhite;
                }
              }
            }

            a {
              color: $colorWhite;
              width: 100%;
              border-bottom: 1px solid $colorDarkWhite;

              span {
                &.text {
                  &.next {
                    display: none;
                  }
                }

                &.toggle {
                  box-sizing: border-box;
                  display: block;
                  width: 4rem;

                  &.next {
                    text-align: right;

                    &:after {
                      font-family: "Font Awesome 6 Pro";
                      font-weight:300;
                      content: "\f054";
                      font-size: 1rem;
                      vertical-align: middle;
                    }
                  }

                  &.prev {
                    width: 2rem;

                    &:after {
                      font-family: "Font Awesome 6 Pro";
                      font-weight:300;
                      content: "\f053";
                      font-size: 1rem;
                      vertical-align: middle;
                    }
                  }
                }
              }
            }

            .container {
              transition: all ease 0.2s;
              display: none;
              position: absolute;
              max-width: 100%;
              width: 100%;
              top: 0;
              left: 100%;
              background: $colorPrimary;
              height: 100%;
              padding: 2.5rem 0;

              &.active {
                display: block;
              }

              .submenu {
                height: 100%;
                border-left: 1px solid $colorDarkWhite;
                overflow-y: auto;
                overflow-x: hidden;
                padding: 2.5rem 3rem 0 3rem;

                li {
                  display: flex;
                  flex-direction: column;
                  width: 100%;

                  &.prev {
                    a {
                      display:none;
                      justify-content: flex-start;
                    }
                  }

                  a {
                    color: $colorWhite;
                    width: 100%;
                    border-bottom: 1px solid $colorDarkWhite;
                    padding: 1rem 0;
                    display: flex;
                    justify-content: space-between;
                    font-weight: 500;
                    font-size: 1.4rem;
                    line-height: 3rem;
                    letter-spacing: 4px;
                    text-transform: uppercase;

                    &.active,
                    &:hover {
                      color: $colorSecondary;
                    }

                    span {
                      &.toggle {
                        max-height:3rem;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .mmenu-footer {
      padding: 0 3rem 2.5rem 3rem;

      .section {
        &.links {
          a {
            font-weight: 500;
            font-size: 1.4rem;
            line-height: 3rem;
            letter-spacing: 4px;
            text-transform: uppercase;
            color:$colorSecondary;
            text-align: center;
            padding-bottom: 4px;

            &.arrow {
              border-bottom:2px solid;
              border-color:transparent;

              &:before {
                font-family: "Font Awesome 6 Pro";
                content: "\f078";
                width: 2rem;
                display: inline-block;
              }

              &:hover {
                border-color:$colorSecondary;

                &:before {
                  content: "\f054";
                }
              }
            }
          }
        }
      }
    }

  }
}

@media only screen and (max-width: $desktop) {
  .mmenu-wrapper {
    top: 9rem;

    .mmenu-content {
      .mmenu-main {
        .navigation {
          ul {
            li {
              &.level0 {
                > a {
                  font-size: 2.4rem;
                }
              }

              .container {
                left: 0;

                .submenu {
                  border-left: none;

                  li {
                    &.prev {
                      a {
                        display: flex;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .mmenu-footer {
        .section {
          &.links {
            a {
              font-size: 1.1rem;
              line-height: 2.4rem;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $tablet) {
  .mmenu-wrapper {
    top: 8rem;
  }
}