// width
$maxWidth: 1920px;
$largeWidth: 1785px;
$mediumWidth: 1650px;
$smallWidth: 1168px;
$tinyWidth: 927px;

// breakpoints
$largeDesktop: 1500px;
$desktop: 1200px;
$smallDesktop: 1024px;
$tablet: 768px;
$phone: 480px;
$smallPhone: 360px;

// images
$imageDir: "../../images/";
// icons
$iconDir: "../../images/icons/";

// Font-size
$fontSizeDefault: 2rem;
$fontSizeDefaultSmall: 1.6rem;
$fontSizeSmall: 1.2rem;
$fontSizeInput: 1.4rem;
/*$fontTable:1.4rem;
$fontButton:1.6rem;*/

// Line-height
$lineHeightDefault: 3rem;
$lineHeightDefaultSmall: 2.6rem;
$lineHeightSmall: 1.7rem;
$lineHeightTable: normal;

// Fontfamily
$font: '';
$fontIcon: "Font Awesome 6 Pro";
$fontSpecial: $font;

// Personalized Primary colors
$colorPrimary: #000;
$colorSecondary: #B5221F;
$colorFontDefault: $colorPrimary;
$colorTextLight: $colorPrimary;
$colorLightSecondary: rgba($colorSecondary, .4);

// Email colors
$colorEmailPrimary: #000;
$colorEmailSecondary: #B5221F;
$colorEmailBackground: #F5F5F5;
$colorEmailHeader: #EEEFEF;
$colorEmailFooter: $colorEmailPrimary;

// Personalized colors
$colorBorder: #000;
$colorInputBorder: #484C5261;
$colorButton: #000;
$colorPlaceholder:#333;

// Grey
$colorLightGrey: rgba($colorPrimary, .5);
$colorGrey: #F2F2F2;
$colorDarkGrey: rgba($colorPrimary, .5);
$colorSwatchGrey: rgba($colorPrimary, .5);

// Default colors
$colorError: $colorSecondary;
$colorSuccess: #53a653;
$colorInfo: #000;
$colorWarning: #F7B234;
$colorWhite: #fff;
$colorDarkWhite: rgba($colorWhite, .6);  ;
$colorBlack: #000;
$colorRed: $colorSecondary;

// icons: use fontawesome pro unicode - leave empty for no icon
$iconMenu: "\f0c9";
$iconWishlisht: "\f004";
$iconCompare: "\f080";
$iconClose: "\f00d";
$iconTrash: "\f2ed";
$iconEdit: "\f040";
$iconGrid: "\f009";
$iconList: "\f03a";
$iconArrow: "\f077";
$iconPagination: "\f054";
$iconCheck: "\f00c";
$iconAsterix: "\f069";
$iconQuestion: "\f059";
$iconError: "\f06a";
$iconInfo: "\f05a";
$iconWarning: "\f071";
$iconToggle: "\f0c9";
$iconSearch: "\f002";
$iconSortArrow: "\f0dd";
$iconSortAsc: "\f160";
$iconSortDesc: "\f161";
$iconKey: "\f084";
$iconPerson: "\f007";
$iconSearch: "\f002";
$iconCart: "\f07a";
$iconBag: "\f290";
$iconPlus: "\f067";
$iconMinus: "\f068";
$iconEye: "\f06e";
$iconPhone: "\f095";
$iconLetter: "\f2b6";
$iconStar: "\f005";
$iconRefresh: "\f2f1";
$iconCalendar: "\f133";
$iconCrosshairs: "\f05b";
$iconRefresh: "\f2f1";
