.minicart {
  &-wrapper {
    text-align: center;

    > a {
      &:before {
        @include fontIcon($sizeValue: 2);
        content: $iconCart;
        width: 100%;
        text-align: center;
      }
      .text {
        display: none;
      }
      .counter {
        &.empty {
          .counter {
            &-number {
              display: none;
            }
          }
        }
        &-number {
          background: $colorSecondary;
          display: inline-block;
          width: 2rem;
          height: 2rem;
          font-size: 1.1rem;
          line-height: 2rem;
          color: $colorWhite;
          text-align: center;
          border-radius: 50px;
          letter-spacing: 0.195px;
          position: absolute;
        }
        &-label {
          display: none;
        }
      }
    }
    a.action{
      @include flex($direction: row, $wrap: true);
      line-height: normal;
    }
  }
}

@media only screen and (max-width: $smallDesktop) {
  .minicart {
    &-wrapper {
      > a {
        &:before {
          font-size:1.6rem;
        }
      }
    }
  }
}

@media only screen and (max-width: $tablet) {
  .minicart {
    &-wrapper {
      > a {
        .counter {
          &-number {
            width: 1.5rem;
            height: 1.5rem;
            line-height: 1.5rem;
          }
        }
      }
    }
  }
}