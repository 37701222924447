.page,
.checkout-container {
  .messages {
    width: 100%;

    .message {
      display: block;
      margin: 0 auto 2rem auto;
      position: relative;
      padding: 1rem 2rem;
      width: 100%;
      color: $colorWhite;

      a {
        color: $colorWhite;
        text-decoration: underline;
      }

      &.error {
        background: $colorError;
      }

      &.success {
        background: $colorSuccess;
      }

      &.info,
      &.empty {
        background: $colorInfo;
      }

      &.warning,
      &.notice {
        background: $colorWarning;
      }
    }
  }
}
