.page-main,
.page-content {
  .navigation {
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      li {
        &.level0 {
          &:last-child {
            margin-right:0;
          }

          > a {
            border-bottom:2px solid;
            border-color:transparent;

            &:before {
              font-family: "Font Awesome 6 Pro";
              content: "\f078";
              width: 2rem;
              display: inline-block;
            }

            &.active,
            &:hover {
              border-color:$colorPrimary;

              &:before {
                content: "\f054";
              }
            }
          }
        }

        &.level1 {
          > a {
            margin-bottom:2rem;
          }
        }

        &.level2 {
          width:100%;

          a {
            color:$colorLightGrey;
          }
        }

        &.red {
          a {
            color:$colorSecondary;

            &:hover {
              border-color: $colorSecondary;
            }
          }
        }

        a {
          font-weight: 500;
          font-size: 1.4rem;
          line-height: 3rem;
          letter-spacing: 4px;
          text-transform: uppercase;
          color:$colorPrimary;
          display: block;
        }

        .container {
          &.level0 {
            @include spaceX(2.5rem);
            background-color:$colorWhite;
            display:none;
            position:absolute;
            z-index: 9;
            left: 0;
            width:100%;
            min-height: 30rem;
            box-shadow: 1px 6px 5px -5px rgba(0, 0, 0, 0.25);
            max-height: 100vh;
            overflow-y: scroll;

            &.active {
              display:block;
            }

            > ul {
              @include content(medium);
              @include spaceY(7rem);
              display: grid;
              grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr));
              column-gap: 2rem;
              row-gap: 8rem;
              justify-content: space-between;
            }
          }
        }
      }
    }
  }
}
